
import axios from 'axios';
import { mapActions, mapState, mapWritableState } from 'pinia';
import { defineComponent } from 'vue';
import { IDiscordAccount, ISteamAccount } from '../../entities/player';
import { useMainStore } from '../../store/mainStore';
import { useOrgStore } from '../../store/orgStore';
import { useTokenStore } from '../../store/tokenStore';

export default defineComponent({
  computed: {
    getDiscordGuildInviteUri(): string | undefined {
      if (!this.settings) {
        return undefined;
      }

      if (this.settings.discordGuildInviteUri.startsWith('http')) {
        return this.settings.discordGuildInviteUri;
      }

      return `https://${this.settings.discordGuildInviteUri}`;
    },
    isDiscordGuildMember(): boolean {
      return this.player?.discordAccounts.filter(
        (account: IDiscordAccount) => account.isGuildMember,
      ).length > 0;
    },
    isSteamGroupMember(): boolean {
      return this.player?.steamAccounts.filter(
        (account: ISteamAccount) => account.isSteamGroupMember,
      ).length > 0;
    },
    ...mapWritableState(useMainStore, ['loading']),
    ...mapState(useOrgStore, ['settings']),
    ...mapState(useTokenStore, ['expiration', 'isAuthenticated', 'player']),
  },
  data() {
    return {
      onlineMembers: 0,
      showDiscordModal: false,
      showSteamModal: false,
      steamID: undefined as string | undefined,
      loadingSteamIDs: [] as string[],
    };
  },
  methods: {
    ...mapActions(useOrgStore, ['getOrg']),
    ...mapActions(useTokenStore, [
      'getToken',
      'redirectToDiscord',
      'redirectToSteam',
      'refreshSteamAccount',
    ]),
    openPopup() {
      const popup = window.open(
        `https://steamcommunity.com/gid/${this.settings?.steamGroupId}`,
        'Steam Group',
        'width=1200,height=600',
      );

      if (!popup) {
        return;
      }

      popup.focus();

      const timer = setInterval(() => {
        if (popup.closed) {
          clearInterval(timer);
          if (this.steamID) {
            this.showSteamModal = false;
            this.refreshSteamGroups(this.steamID);
          }
        }
      }, 500);
    },
    async refreshSteamGroups(steamID: string) {
      if (this.loadingSteamIDs.includes(steamID)) {
        return;
      }

      this.loadingSteamIDs.push(steamID);
      await this.refreshSteamAccount(steamID);
      this.loadingSteamIDs = this.loadingSteamIDs.filter((id: string) => id !== steamID);
    },
  },
  async mounted() {
    const bootstrapScript = document.createElement('script');
    bootstrapScript.setAttribute('src', 'https://theprojectlazarus.com/js/bootstrap.bundle.min.js');
    document.body.appendChild(bootstrapScript);

    const particleScript = document.createElement('script');
    particleScript.setAttribute('src', 'https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js');
    document.body.appendChild(particleScript);

    const customScript = document.createElement('script');
    customScript.setAttribute('src', 'https://theprojectlazarus.com/js/custom.js');
    document.body.appendChild(customScript);

    try {
      const { data } = await axios.get<{ 'presence_count': number }>(
        'https://discordapp.com/api/guilds/675718649973309481/embed.json',
      );

      this.onlineMembers = data.presence_count;
    } catch {
      //
    }

    await this.getOrg();

    if (!this.isAuthenticated) {
      await this.getToken();
    }

    this.loading = false;

    const { steamID } = this.$route.query;

    if (!steamID) {
      return;
    }

    this.$router.replace({ name: 'Home' });

    if (!this.settings?.steamGroupId) {
      return;
    }

    this.steamID = steamID.toString();

    const account = this.player.steamAccounts.find(
      (a: ISteamAccount) => a.steamId === this.steamID,
    );

    if (account.isSteamGroupMember) {
      return;
    }

    this.showSteamModal = true;
  },
});
